<template>
  <div style="background-color: #f6f8fa">
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-center pa-5 style="background-color: #ffffff">
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex xs12 md6 sm6 lg6 class="text-left" align-self-center>
                <span class="heading" style="color: rgba(0, 0, 0, 0.8)"
                  >Dashboard</span
                >
              </v-flex>
              <v-flex xs12 md6 sm6 lg6 class="text-right">
                <v-layout wrap justify-end>
                  <v-flex xs12 lg4 sm4 md4>
                    <v-select
                      :items="dateitems"
                      dense
                      hide-details="true"
                      solo
                      flat
                      background-color="#D1E0FCB2"
                      prepend-inner-icon="mdi-calendar-text"
                      v-model="datefilter"
                      class="custom-select-background dropdown"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-start pa-5>
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex
                xs12
                md3
                sm3
                lg3
                v-for="(card, index) in cardData"
                :key="index"
                pa-1
              >
                <v-card elevation="3">
                  <v-layout wrap justify-start pa-5>
                    <v-flex xs12>
                      <v-layout>
                        <v-flex xs3>
                          <v-layout wrap justify-start>
                            <v-flex xs12 text-left>
                              <v-img :src="card.image"></v-img>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs9 pl-2>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <span class="cardheader">{{ card.title }}</span>
                            </v-flex>
                            <v-flex xs12>
                              <span class="cardvalue">{{ card.value }}</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-center pa-5>
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex xs12 sm12 md12 lg6>
                <v-card elevation="1" height="100%">
                  <v-layout wrap justify-start px-5 pt-5>
                    <v-flex xs12>
                      <v-layout wrap justify-start>
                        <v-flex xs12
                          ><span class="reportone"
                            >Conflict Reports by Animal Type</span
                          ></v-flex
                        >
                        <v-flex xs12 pt-2
                          ><span class="reporttwo"
                            >Detailed Breakdown of Incidents Across
                          </span></v-flex
                        >
                        <v-flex xs12 pt-3
                          ><span class="reportthree"
                            >Total Conflicts Reported</span
                          ></v-flex
                        >
                        <v-flex xs12
                          ><span class="reportfour">{{
                            totalconflictdata
                          }}</span></v-flex
                        >
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap pa-0 justify-center>
                    <v-flex xs10 pa-0 v-if="flag">
                      <!-- <apexchart
                        width="380"
                        height="225px"
                        type="donut"
                        :options="options"
                        :series="series"
                      ></apexchart> -->
                      <highcharts
                        :options="highchartsOptions"
                        :constructor-type="'chart'"
                      ></highcharts>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 sm12 md12 lg6 pl-lg-2 pt-sm-md-2>
                <v-card elevation="1" height="100%">
                  <v-layout wrap justify-start pa-5>
                    <v-flex xs12>
                      <v-layout wrap justify-start>
                        <v-flex xs12
                          ><span class="reportone"
                            >Animal Conflict Reports by District Wise</span
                          ></v-flex
                        >
                        <v-flex xs12 pt-2
                          ><span class="reporttwo"
                            >Detailed Breakdown of Incidents Across
                          </span></v-flex
                        >
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pt-4>
                      <v-layout wrap justify-start>
                        <v-flex xs12 md4 sm4 lg4>
                          <v-card
                            elevation="0"
                            style="background-color: #f7f7f7"
                          >
                            <v-layout wrap justify-start pa-5>
                              <v-flex xs12>
                                <span class="reportfive"
                                  >Total conflicts Reported</span
                                >
                              </v-flex>
                              <v-flex xs12>
                                <v-layout wrap justify-start>
                                  <v-flex
                                    xs12
                                    sm6
                                    md6
                                    lg6
                                    class="text-left"
                                    align-self-start
                                  >
                                    <v-img
                                      :src="
                                        require('@/assets/images/mapicon.png')
                                      "
                                      contain
                                      height="45px"
                                    ></v-img
                                  ></v-flex>
                                  <v-flex
                                    xs12
                                    sm6
                                    md6
                                    lg6
                                    class="text-right"
                                    align-self-center
                                    ><span class="reportsix">{{
                                      totalReports
                                    }}</span></v-flex
                                  >
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </v-flex>
                        <v-flex xs12 md4 sm4 lg4 pl-2>
                          <v-layout wrap justify-start pb-1>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#786D17"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Idukki</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span
                                      class="districtvalue"
                                      v-if="
                                        districtWiseCounts &&
                                        districtWiseCounts[5]
                                      "
                                      >{{ districtWiseCounts[5].count }}</span
                                    >
                                    <span v-else>0</span></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#B83E89"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Malappuram</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span
                                      class="districtvalue"
                                      v-if="
                                        districtWiseCounts &&
                                        districtWiseCounts[10]
                                      "
                                      >{{ districtWiseCounts[10].count }}</span
                                    >
                                    <span v-else>0</span></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 md4 sm4 lg4 pl-2>
                          <v-layout wrap justify-start pb-1>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#9B6AD0"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Kollam</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span
                                      class="districtvalue"
                                      v-if="
                                        districtWiseCounts &&
                                        districtWiseCounts[0]
                                      "
                                      >{{ districtWiseCounts[0].count }}</span
                                    >
                                    <span v-else>0</span></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#22D6C4"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Ernakulam</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span
                                      class="districtvalue"
                                      v-if="
                                        districtWiseCounts &&
                                        districtWiseCounts[6]
                                      "
                                      >{{ districtWiseCounts[6].count }}</span
                                    >
                                    <span v-else>0</span></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pt-1>
                      <v-layout wrap justify-start>
                        <v-flex xs12 md4 sm4 lg4>
                          <v-layout wrap justify-start pb-1>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#FB38BB"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Kozhikode</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span
                                      class="districtvalue"
                                      v-if="
                                        districtWiseCounts &&
                                        districtWiseCounts[9]
                                      "
                                      >{{ districtWiseCounts[9].count }}</span
                                    >
                                    <span v-else>0</span></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#6BDA7C"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Alappuzha</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span
                                      class="districtvalue"
                                      v-if="
                                        districtWiseCounts &&
                                        districtWiseCounts[3]
                                      "
                                      >{{ districtWiseCounts[3].count }}</span
                                    >
                                    <span v-else>0</span></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 md4 sm4 lg4 pl-2>
                          <v-layout wrap justify-start pb-1>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#F05429"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Kottayam</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span
                                      class="districtvalue"
                                      v-if="
                                        districtWiseCounts &&
                                        districtWiseCounts[4]
                                      "
                                      >{{ districtWiseCounts[4].count }}</span
                                    >
                                    <span v-else>0</span></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#EDED59"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Thrissur</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span
                                      class="districtvalue"
                                      v-if="
                                        districtWiseCounts &&
                                        districtWiseCounts[12]
                                      "
                                      >{{ districtWiseCounts[12].count }}</span
                                    >
                                    <span v-else>0</span></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 md4 sm4 lg4 pl-2>
                          <v-layout wrap justify-start pb-1>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#CD186B"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Kannur</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span
                                      class="districtvalue"
                                      v-if="
                                        districtWiseCounts &&
                                        districtWiseCounts[7]
                                      "
                                      >{{ districtWiseCounts[7].count }}</span
                                    >
                                    <span v-else>0</span></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#7D0460"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Trivandrum</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span
                                      class="districtvalue"
                                      v-if="
                                        districtWiseCounts &&
                                        districtWiseCounts[1]
                                      "
                                      >{{ districtWiseCounts[1].count }}</span
                                    >
                                    <span v-else>0</span></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pt-1>
                      <v-layout wrap justify-start>
                        <v-flex xs12 md4 sm4 lg4>
                          <v-layout wrap justify-start pb-1>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#76CC1C"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Pathanamthitta</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span
                                      class="districtvalue"
                                      v-if="
                                        districtWiseCounts &&
                                        districtWiseCounts[2]
                                      "
                                      >{{ districtWiseCounts[2].count }}</span
                                    >
                                    <span v-else>0</span></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 md4 sm4 lg4 pl-2>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#8ABC30"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Palakkad</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span
                                      class="districtvalue"
                                      v-if="
                                        districtWiseCounts &&
                                        districtWiseCounts[11]
                                      "
                                      >{{ districtWiseCounts[11].count }}</span
                                    >
                                    <span v-else> 0</span></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 md4 sm4 lg4 pl-2>
                          <v-layout wrap justify-start pb-1>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#563CF0"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Wayanad</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span
                                      class="districtvalue"
                                      v-if="
                                        districtWiseCounts &&
                                        districtWiseCounts[13]
                                      "
                                      >{{ districtWiseCounts[13].count }}</span
                                    >
                                    <span v-else>0</span></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pb-3>
                      <v-layout wrap justify-start>
                        <v-flex xs12 md4 sm4 lg4>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs1>
                                    <v-avatar
                                      color="#EE7D47"
                                      size="10"
                                    ></v-avatar>
                                  </v-flex>
                                  <v-flex xs9
                                    ><span class="districtname"
                                      >&nbsp;Kasaragod</span
                                    ></v-flex
                                  >
                                  <v-flex xs2
                                    ><span
                                      class="districtvalue"
                                      v-if="
                                        districtWiseCounts &&
                                        districtWiseCounts[8]
                                      "
                                      >{{ districtWiseCounts[8].count }}</span
                                    >
                                    <span v-else>0</span></v-flex
                                  >
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-center pa-5>
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex xs12 sm12 md12 lg6>
                <v-card elevation="1" height="100%">
                  <v-layout wrap justify-start pa-5>
                    <v-flex xs12>
                      <v-layout wrap justify-start>
                        <v-flex xs12
                          ><span class="reportone"
                            >Annual Overview of Animal Conflict Reports</span
                          ></v-flex
                        >
                        <v-flex xs12
                          ><span class="reporttwo"
                            >Yearly breakdown of reported animal conflict
                            incidents
                          </span></v-flex
                        >
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pt-2>
                      <v-flex xs12>
                        <div id="chart2" v-if="flag2">
                          <!-- <apexchart
                            type="bar"
                            height="262"
                            :options="chartOptions3"
                            :series="series3"
                          ></apexchart> -->
                          <highcharts :options="histogramOptions"></highcharts>
                        </div>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 sm12 md12 lg6 pl-lg-2 pt-md-sm-2>
                <v-card elevation="1" height="100%">
                  <v-layout wrap justify-start pa-5>
                    <v-flex xs12>
                      <v-layout wrap justify-start>
                        <v-flex xs12 lg9>
                          <v-layout wrap justify-start>
                            <v-flex>
                              <span class="reportone"
                                >Animal Conflicts by Threat Level</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 lg3>
                          <v-select
                            outlined
                            dense
                            :hide-details="true"
                            :items="yearitems"
                            v-model="lineyeardata"
                          ></v-select>
                        </v-flex>
                        <v-flex xs12
                          ><span class="reporttwo"
                            >shows a visual breakdown of animal conflicts by
                            threat level.
                          </span></v-flex
                        >
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pt-2>
                      <v-layout wrap justify-start>
                        <v-flex xs12 v-if="flag1">
                          <!-- <apexchart
                            type="line"
                            height="248px"
                            :options="linechartoptions"
                            :series="linechartseries"
                          ></apexchart> -->
                          <highcharts
                            :options="linehighchartsOptions"
                            ref="linehighchartsRef"
                          ></highcharts>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-center pa-5>
          <v-flex xs12>
            <v-card elevation="1">
              <v-layout wrap justify-center pa-5>
                <v-flex xs12>
                  <v-layout wrap justify-center>
                    <v-flex xs12 align-self-center class="text-center filter">
                      <span>Filter Data By</span>
                    </v-flex>
                    <v-flex xs12>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-start pt-4 class="filtersub">
                    <v-flex xs12 sm4 md4 lg4>
                      <span>Division</span>
                      <v-select
                        outlined
                        dense
                        hide-details
                        v-model="divisionname"
                        :items="divisionlist"
                        item-text="divisionName"
                        item-value="_id"
                      >
                      </v-select>
                    </v-flex>
                    <v-flex xs12 sm4 md4 lg4 pl-lg-2 pl-md-2 pl-sm-2>
                      <span>Range</span>
                      <v-select
                        outlined
                        dense
                        hide-details
                        v-model="rangename"
                        item-text="rangeName"
                        item-value="_id"
                        :items="rangelist"
                        :disabled="divisionname ? false : true "
                      >
                      </v-select>
                    </v-flex>
                    <v-flex xs12 sm4 md4 lg4 pl-lg-2 pl-md-2 pl-sm-2>
                      <span>RRT</span>
                      <v-select
                        outlined
                        dense
                        hide-details
                        v-model="rrtname"
                        :items="rrtlist"
                        item-text="name"
                        item-value="_id"
                        :disabled="rangename ? false : true "
                      >
                      </v-select>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-start pt-4 class="filtersub">
                    <v-flex xs12 sm4 md4 lg4>
                      <span>Start Date</span>
                      <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="startdate"
                            append-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            hide-details="true"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="startdate"
                          @input="menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs12 sm4 md4 lg4 pl-lg-2 pl-md-2 pl-sm-2>
                      <span>End Date</span>
                      <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="enddate"
                            append-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            hide-details="true"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="enddate"
                          @input="menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex
                      xs12
                      sm4
                      md4
                      lg4
                      pl-lg-2
                      pl-md-2
                      pl-sm-2
                      align-self-center
                      pt-5
                    >
                      <v-btn color="success">
                        <span>Apply</span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
    
    <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      flag: false,
      flag1: false,
      flag2: false,

      barflag: false,
      dateitems: [
        "Today",
        "Yesterday",
        "Last 7 days",
        "Last 30 days",
        "Last 60 days",
        "Last 90 days",
      ],
      options: {
        chart: {
          type: "donut",
        },
        labels: [], // For the animal names
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      series: [],
      datefilter: "Last 30 days",
      cardData: [
        {
          image: require("@/assets/images/humandeath.png"),
          title: "Total Human Deaths",
          value: "",
        },
        {
          image: require("@/assets/images/animaldeath.png"),
          title: "Total Animal Deaths",
          value: "",
        },
        // {
        //   image: require("@/assets/images/humaninjury.png"),
        //   title: "Total Human Injury ",
        //   value: "",
        // },
        {
          image: require("@/assets/images/animalcapptured.png"),
          title: "Total Animals Captured",
          value: "",
        },
        {
          image: require("@/assets/images/animalreleased.png"),
          title: "Total Animals Released",
          value: "",
        },
      ],
      yearData: [
        {
          year: 2024,
          value: 5234,
          icon: "mdi-calendar",
        },
        {
          year: 2023,
          value: 5234,
          icon: "mdi-calendar",
        },
        {
          year: 2022,
          value: 5234,
          icon: "mdi-calendar",
        },
        {
          year: 2021,
          value: 5234,
          icon: "mdi-calendar",
        },
        {
          year: 2020,
          value: 5234,
          icon: "mdi-calendar",
        },
        {
          year: 2019,
          value: 5234,
          icon: "mdi-calendar",
        },
      ],
      linechartseries: [
        {
          name: "Low threat",
          data: [50, 40, 60, 70, 80],
        },
        {
          name: "Moderate threat",
          data: [30.2, 30, 10, 50, 60],
        },
        {
          name: "High threat",
          data: [20, 25, 45, 67, 30],
        },
      ],
      linechartoptions: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#07E098", "#F6D635", "#F33232"],
      },
      yearitems: ["2023", "2024"],
      lineyeardata: "2024",
      totalAnimalDeaths: "",
      totalReleased: "",
      totalCaptured: "",
      totalHumanDeaths: "",
      totalHumanInjuries: "",
      series3: [
        {
          data: [],
          name: "CGPA",
        },
      ],
      chartOptions3: {
        chart: {
          height: 262,
          type: "bar",
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
      },
      divisionname: "",
      divisionlist: [
      ],
      rangename: "",
      rangelist: [
      ],
      rrtname: "",
      totalconflictdata: 0,
      districtWiseCounts: [],
      rrtlist: [
      ],
      yearDatagraph: [],
      startdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      enddate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu1: false,
      highchartsOptions: {
        chart: {
          type: "pie",
          height: 400,
        },
        exporting: {
          enabled: false, // Disable the export menu
        },
        credits: {
        enabled: false // Disable the Highcharts watermark
      },
        title: {
          text: null,
          enabled: true,
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            borderRadius: 6,
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %",
            },
          },
        },
        series: [
          {
            name: "Reports",
            colorByPoint: true,
            innerSize: "75%",
            data: [], // Data will be dynamically populated
          },
        ],
      },

      totalReports: 0,
      seriesData: [],
      linehighchartsOptions: {
        chart: {
          type: "area",
          height: 350,
        },
        exporting: {
          enabled: false, // Disable the export menu
        },
        credits: {
        enabled: false // Disable the Highcharts watermark
      },
        title: {
          text: null, // To disable the title text
        },
        xAxis: {
          categories: [], // Will be populated with months from backend
        },
        yAxis: {
          title: {
            text: "Total Reports",
          },
        },
        series: [
          { name: "High threat", data: [] },
          { name: "Moderate threat", data: [] },
          { name: "Low threat", data: [] },
        ],
        colors: ["#F33232", "#F6D635", "#07E098"], // Update colors to match previous chart
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
            },
            enableMouseTracking: true,
          },
        },
      },
      histogramOptions: {
        chart: {
          type: "column", // Change to 'column' for histogram
          height: 400,
        },
        exporting: {
          enabled: false, // Disable the export menu
        },
        credits: {
        enabled: false // Disable the Highcharts watermark
      },
        title: {
          text: "",
        },
        xAxis: {
          categories: [], // This will be populated with year data
          title: {
            text: "Year",
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Total Reports",
          },
        },
        plotOptions: {
          column: {
            pointWidth: 30, // Set the width of the columns (adjust this value)
            dataLabels: {
              enabled: true, // Enable data labels if needed
            },
          },
        },
        series: [
          {
            name: "Reports",
            data: [], // This will be populated with values from yearData
          },
        ],
      },
    };
  },
  beforeMount() {
    this.getData();
    this.animalcountData();
    this.threatlevelData();
    this.districtCount();
    this.yearDatafull();
    this.getDivisionData();
    this.getRangeData();
    this.getRRTData();
  },
  watch: {
    divisionname(){
      this.getRangeData();
    },
    rangename(){
    this.getRRTData();
    },
  
  },
  methods: {
    getDivisionData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/platform/division/getlistdropdown",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.divisionlist = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getRangeData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/platform/range/getlistdropdown",
        headers: {
          token: localStorage.getItem("token"),
        },
        data:{
          division:this.divisionname
        }
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.rangelist = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getRRTData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/rescuer/rrt/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params:{
          division:this.divisionname,
          range:this.rangename,
        }
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.rrtlist = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/hwc/dashboard/count",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.cardData[0].value = response.data.data.totalHumanDeaths;
            this.cardData[1].value = response.data.data.totalAnimalDeaths;
            // this.cardData[2].value = response.data.data.totalHumanInjuries;
            this.cardData[2].value = response.data.data.totalCaptured;
            this.cardData[3].value = response.data.data.totalReleased;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    animalcountData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/hwc/animalsummary/reports",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            const animalReportSummary = response.data.data.animalReportSummary;
            this.series = animalReportSummary.map((item) => item.totalReports);
            this.options.labels = animalReportSummary.map((item) => item.name);
            this.totalconflictdata = response.data.data.totalReports;
            const seriesData = animalReportSummary.map((item) => ({
              name: item.name,
              y: item.totalReports,
            }));
            this.highchartsOptions.series[0].data = seriesData;
            this.flag = true;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    threatlevelData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/hwc/threatlevel/reports",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            const threatData = response.data.data;
            const months = threatData.map((item) => item.month);
            const highThreat = threatData.map((item) => {
              const level = item.threatLevels.find(
                (level) => level.threatlevel === "High"
              );
              return level ? level.totalReports : 0;
            });
            const mediumThreat = threatData.map((item) => {
              const level = item.threatLevels.find(
                (level) => level.threatlevel === "Medium"
              );
              return level ? level.totalReports : 0;
            });
            const lowThreat = threatData.map((item) => {
              const level = item.threatLevels.find(
                (level) => level.threatlevel === "Low"
              );
              return level ? level.totalReports : 0;
            });
            this.linechartseries = [
              { name: "High threat", data: highThreat },
              { name: "Moderate threat", data: mediumThreat },
              { name: "Low threat", data: lowThreat },
            ];
            this.linechartoptions = {
              ...this.linechartoptions,
              xaxis: {
                categories: months, // Months as x-axis labels
              },
            };
            this.linehighchartsOptions.xAxis.categories = months;
            this.linehighchartsOptions.series[0].data = highThreat;
            this.linehighchartsOptions.series[1].data = mediumThreat;
            this.linehighchartsOptions.series[2].data = lowThreat;
            this.flag1 = true;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    districtCount() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/hwc/reportcounts/districtwise",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          filter: this.datefilter,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.districtWiseCounts = response.data.data.districtWiseCounts;
            this.totalReports = response.data.data.totalReports;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    yearDatafull() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/hwc/reportcounts/yearwise",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        // data: {
        //   filter: this.datefilter,
        // },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.yearDatagraph = response.data.data;
            this.histogramOptions.xAxis.categories = this.yearDatagraph.map(
              (item) => item.year
            );
            this.histogramOptions.series[0].data = this.yearDatagraph.map(
              (item) => item.totalReports
            );
            this.appLoading = false;
            this.flag2 = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
    
    <style>
.heading {
  font-family: poppinsregular;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}
.dropdown {
  font-family: poppinsregular;
  font-weight: 500;
  font-size: 15px;
  line-height: 22.5px;
}
.custom-select-background .v-input__control {
  background-color: rgba(209, 224, 252, 0.7);
}
.cardheader {
  font-family: poppinsregular;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* opacity: 50%; */
  color: #797979;
}
.cardvalue {
  font-family: poppinsregular;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
}
.reportone {
  font-family: poppinsregular;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}
.reporttwo {
  font-family: poppinsregular;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 1);
  /* opacity: 50%; */
  color: #797979;
}
.reportthree {
  font-family: poppinsregular;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.reportfour {
  font-family: poppinsregular;
  font-weight: 700;
  font-size: 35px;
  line-height: 52.5px;
}
.reportfive {
  font-family: poppinsregular;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #494949;
}
.reportsix {
  font-family: poppinsregular;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}
.districtname {
  font-family: poppinsregular;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  color: #797979;
}
.districtvalue {
  font-family: poppinsregular;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
}
.yearhead {
  font-family: poppinsregular;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.yearsub {
  font-family: poppinsregular;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}
.filter {
  font-family: poppinsregular;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.filtersub {
  font-family: poppinsregular;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}
</style>
    